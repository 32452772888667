<template>
  <div>
    <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
      <Head>
        <template v-for="link in head.link" :key="link.hid">
          <Link :id="link.hid" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
        </template>
        <template v-for="meta in head.meta" :key="meta.hid">
          <Meta :id="meta.hid" :property="meta.property" :content="meta.content" />
        </template>
      </Head>
      <Body>
        <section
          :data-theme="theme"
          class="tw-overflow-x-hidden tw-font-normal"
          :class="{
            'tw-font-poppins': !isSosoValue,
            'tw-font-space_grotesk': isSosoValue,
          }"
        >
          <section class="tw-flex tw-flex-col tw-min-h-screen tw-h-full">
            <slot />
            <CommonFooter />
          </section>
          <CommonBackToTop />
          <CommonSetting />
        </section>
      </Body>
    </Html>
  </div>
</template>
<script setup lang="ts">
  const { isSosoValue } = useSite()
  const route = useRoute()
  const head = useLocaleHead()
  const theme = computed(() => (unref(isSosoValue) ? 'sosovalue' : 'legend'))
</script>
